﻿<template>
  <component :is="getBlockComponent()" :model="model" :has-container="hasContainer" />
</template>

<script setup>
import CtaWithInputBlock from '@TodayInTheWord/components/blocks/cta-with-input-block/cta-with-input-block.vue'
import TitwFeaturedContentBlock from '@TodayInTheWord/components/blocks/featured-content-block/featured-content-block.vue'
import FeaturedMediaBlock from '@TodayInTheWord/components/blocks/featured-image-video/featured-image-video.vue'
import FeaturedResourcesBlock from '@TodayInTheWord/components/blocks/featured-resources-block/featured-resources-block.vue'
import FormContainerBlock from '@TodayInTheWord/components/blocks/form-container.vue'
import FullWidthCalloutBlock from '@TodayInTheWord/components/blocks/full-width-callout-block/full-width-callout-block.vue'
import GenericBlock from '@TodayInTheWord/components/blocks/generic-block.vue'
import HeroBlock from '@TodayInTheWord/components/blocks/hero/hero-block.vue'
import TriFeatureHeroBlock from '@TodayInTheWord/components/blocks/hero/tri-feature-hero-block.vue'
import TitwHomeHeroBlock from '@TodayInTheWord/components/blocks/home-hero/home-hero.vue'
import ImageBlock from '@TodayInTheWord/components/blocks/image-block/image-block.vue'
import LargeCalloutBlock from '@TodayInTheWord/components/blocks/large-callout-block/large-callout-block.vue'
import LargeSliderBlock from '@TodayInTheWord/components/blocks/large-slider-block/large-slider-block.vue'
import LayoutBlock from '@TodayInTheWord/components/blocks/layout-block/layout-block.vue'
import LinkCardGridBlock from '@TodayInTheWord/components/blocks/link-card-grid-block/link-card-grid-block.vue'
import TitwPromoBannerBlock from '@TodayInTheWord/components/blocks/promo-banner-block.vue'
import RichTextBlock from '@TodayInTheWord/components/blocks/rich-text-block/rich-text-block.vue'
import SiteAlertBlock from '@TodayInTheWord/components/blocks/site-alert/site-alert-block.vue'
import TitwSmallCalloutGridBlock from '@TodayInTheWord/components/blocks/small-callout-grid-block/small-callout-grid-block.vue'
import TitwStudyContentBlock from '@TodayInTheWord/components/blocks/study-content-block/study-content-block.vue'
import TitwTabHeroSliderBlock from '@TodayInTheWord/components/blocks/tab-hero-slider-block/tab-hero-slider-block.vue'
import TableBlock from '@TodayInTheWord/components/blocks/table-block/table-block.vue'

const components = {
  GenericBlock,
  FeaturedMediaBlock,
  RichTextBlock,
  FormContainerBlock,
  HeroBlock,
  TriFeatureHeroBlock,
  FullWidthCalloutBlock,
  LargeCalloutBlock,
  LargeSliderBlock,
  LinkCardGridBlock,
  TableBlock,
  LayoutBlock,
  CtaWithInputBlock,
  ImageBlock,
  TitwPromoBannerBlock,
  SiteAlertBlock,
  TitwFeaturedContentBlock,
  FeaturedResourcesBlock,
  TitwTabHeroSliderBlock,
  TitwHomeHeroBlock,
  TitwStudyContentBlock,
  TitwSmallCalloutGridBlock,
}

const props = defineProps({
  model: {
    type: Object,
    default: () => ({}),
  },
  hasContainer: {
    type: Boolean,
    default: true,
  },
})

const getBlockComponent = () => {
  if (props.model && props.model.contentType && components) {
    // Find a component that matches the content type name.
    for (let i = props.model.contentType.length - 1; i >= 0; i--) {
      const type = props.model.contentType[i]
      const component = components[type]

      if (component) {
        return component
      }
    }
  }
  return GenericBlock
}
</script>
