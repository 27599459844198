<template>
  <div v-if="props.model" class="space-y-3">
    <shared-section-heading :heading="props.model.heading" />

    <audio-player
      ref="playingAudio"
      :title="selectedPodcast.title"
      :src="selectedPodcast.streamUrl"
      :isInList="false"
    />
    <div
      v-if="props.model?.items && props.model?.items.length > 1"
      class="border-grey-100 bg-grey-50 max-h-[380px] overflow-y-scroll rounded-xl border px-3 lg:max-h-[424px] lg:px-4"
    >
      <audio-player
        ref="audioPlayer"
        v-for="(player, index) in props.model?.items"
        :key="index"
        :isInList="true"
        :title="player.title"
        :src="player.streamUrl"
        :description="player.description"
        :date="player.date"
        @update-selected="getSelectedPodcast(index)"
        class="border-b-grey-200 border-b last:bg-none"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SharedSectionHeading from '@TodayInTheWord/components/blocks/section-heading/section-heading.vue'
import AudioPlayer from '@TodayInTheWord/components/pages/shared/audio-player.vue'
import { DateTime } from 'luxon'
import { onMounted, ref } from 'vue'
import { PodcastProps, PodcastsSectionProps } from '../types/devotional-monthly-study-types'

const selectedPodcast = ref<Object>({})
const playingAudio = ref()

interface podcastsSectionProps {
  model?: PodcastsSectionProps
}

const props = defineProps<podcastsSectionProps>()

function getSelectedPodcast(key: number) {
  if (props.model?.items) {
    props.model.items.forEach((item: PodcastProps, index: number) => {
      if (key === index) {
        selectedPodcast.value = item
      }
    })
  }
}

function initialPodcast() {
  let currentDate = DateTime.now().toISO()
  currentDate = DateTime.fromISO(currentDate)
  currentDate = currentDate.toFormat('MMM dd, yyyy')
  let podcastMatched: boolean = false

  if (!props.model?.items) {
    return
  } else {
    props.model?.items.forEach((item: PodcastProps) => {
      if (item.date == currentDate) {
        selectedPodcast.value = item
        podcastMatched = true
      }
    })
  }
  //set default if no match
  if (!podcastMatched) {
    selectedPodcast.value = props.model.items[0]
  }
}

onMounted(() => {
  initialPodcast()
})
</script>
