<!--
    PROP OPTIONS:
    emphasis: bold, outline, tonal
    size: smaller, regular, larger
    style: secondary, primary, neutral
-->
<template>
  <a
    v-if="externalLink"
    :href="verifyHref"
    :target="computeTarget"
    :class="activeStyle"
    :aria-label="ariaLabel"
  >
    <slot></slot>
  </a>
  <router-link v-else :to="verifyHref" :class="activeStyle" :aria-label="ariaLabel">
    <slot></slot>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface SharedLinkProps {
  emphasis?: string
  size?: string
  style?: string
  href?: string
  externalLink?: Boolean
  target?: string
  ariaLabel?: string
}

const props = defineProps<SharedLinkProps>()

const computeTarget = computed(() => {
  //   if (
  //     (props.externalLink && (!props.target || props.target === null))
  //  || (props.href && !props.href.includes('todayintheword.org') && !props.href.startsWith('/'))
  //   ) {
  //     return '_blank'
  //   } else {
  return props.target || ''
  // }
})

const verifyHref = computed(() => {
  if (!props.href) {
    return '#'
  } else {
    return props.href
  }
})

const activeStyle = computed(() => {
  const linkGlobal =
    'inline-flex items-center justify-center font-medium border-solid border rounded-xl cursor-pointer transition ease-in-out duration-300'
  let linkStyle = ''
  let linkSize = 'py-2 px-[14px] text-base'

  if (props.style !== undefined) {
    if (props.style?.toLowerCase() === 'secondary') {
      if (props.emphasis && props.emphasis.toLowerCase() === 'outline') {
        linkStyle = `bg-white text-cyan-800 border-cyan-600
                             [@media(hover:hover)]:hover:bg-cyan-700 hover:text-cyan-50  [@media(hover:hover)]:hover:border-cyan-700
                            active:bg-cyan-100 active:text-cyan-800 active:border-cyan-600
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      } else if (props.emphasis && props.emphasis.toLowerCase() === 'tonal') {
        linkStyle = `bg-cyan-100 text-cyan-900 border-cyan-100
                             [@media(hover:hover)]:hover:bg-cyan-700 hover:text-cyan-50  [@media(hover:hover)]:hover:border-cyan-700
                            active:bg-cyan-200 active:text-cyan-900 active:border-cyan-200
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      } else {
        linkStyle = `bg-cyan-600 text-cyan-50 border-cyan-600
                             [@media(hover:hover)]:hover:bg-cyan-700 hover:text-cyan-50  [@media(hover:hover)]:hover:border-cyan-700
                            active:bg-cyan-700 active:text-cyan-50 active:border-cyan-700
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      }
    } else if (props.style?.toLowerCase() === 'neutral') {
      if (props.emphasis && props.emphasis.toLowerCase() === 'outline') {
        linkStyle = `bg-white text-grey-700 border-grey-500
                             [@media(hover:hover)]:hover:bg-grey-100  hover:text-grey-700  [@media(hover:hover)]:hover:border-grey-500
                            active:bg-grey-100 active:text-grey-700 active:border-grey-500
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      } else if (props.emphasis && props.emphasis.toLowerCase() === 'tonal') {
        linkStyle = `bg-grey-200 text-grey-800 border-grey-200
                             [@media(hover:hover)]:hover:bg-grey-300  hover:text-grey-800  [@media(hover:hover)]:hover:border-grey-300
                            active:bg-grey-300 active:text-grey-800 active:border-grey-300
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      } else {
        linkStyle = `bg-grey-800 text-grey-50 border-grey-800
                             [@media(hover:hover)]:hover:bg-grey-600  hover:text-grey-50  [@media(hover:hover)]:hover:border-grey-600
                            active:bg-grey-600 active:text-grey-50 active:border-grey-600
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      }
    } else {
      if (props.emphasis && props.emphasis.toLowerCase() === 'outline') {
        linkStyle = `bg-white text-light-blue-900 border-light-blue-900
                             [@media(hover:hover)]:hover:bg-light-blue-700 hover:text-light-blue-50  [@media(hover:hover)]:hover:border-light-blue-700
                            active:bg-light-blue-100 acitve:text-light-blue-900
                            disabled:text-grey-400 disabled:border-grey-400 disabled:cursor-not-allowed`
      } else if (props.emphasis && props.emphasis.toLowerCase() === 'tonal') {
        linkStyle = `bg-light-blue-100 text-light-blue-900 border-light-blue-100
                             [@media(hover:hover)]:hover:bg-light-blue-700 hover:text-light-blue-50  [@media(hover:hover)]:hover:border-light-blue-700
                            active:bg-light-blue-200 active:text-light-blue-900 active:border-light-blue-200
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      } else {
        linkStyle = `bg-light-blue-900 text-light-blue-50 border-light-blue-900
                            [@media(hover:hover)]:hover:bg-light-blue-700 hover:text-light-blue-50 [@media(hover:hover)]:hover:border-light-blue-700
                            active:bg-light-blue-700 active:text-light-blue-50 active:border-light-blue-700
                            disabled:bg-grey-300 disabled:text-grey-400 disabled:border-grey-300 disabled:cursor-not-allowed`
      }
    }

    if (props.size && props.size.toLowerCase() === 'smaller') {
      linkSize = 'py-2 px-3 text-sm'
    } else if (props.size && props.size.toLowerCase() === 'larger') {
      linkSize = 'py-[10px] px-3 text-lg'
    }

    return `${linkGlobal} ${linkStyle} ${linkSize}`
  } else {
    return ''
  }
})
</script>
