﻿import { defineStore } from 'pinia'

export const useAudioStore = defineStore('audioStore', {
    state: () => ({
        audioState: {} as Record<string, { lastPausedTime: number; wasPlaying: boolean }>, // Map for each audio src
    }),
    actions: {
        // Set the paused time for a specific audio src
        setPausedTime(src: string, time: number) {
            if (!this.audioState[src]) {
                this.audioState[src] = { lastPausedTime: 0, wasPlaying: false }
            }
            this.audioState[src].lastPausedTime = time
        },

        // Set the playing state for a specific audio src
        setWasPlaying(src: string, isPlaying: boolean) {
            if (!this.audioState[src]) {
                this.audioState[src] = { lastPausedTime: 0, wasPlaying: false }
            }
            this.audioState[src].wasPlaying = isPlaying
        },

        // Get the paused time for a specific audio src
        getPausedTime(src: string): number {
            return this.audioState[src]?.lastPausedTime || 0
        },

        // Get the playing state for a specific audio src
        getWasPlaying(src: string): boolean {
            return this.audioState[src]?.wasPlaying || false
        },

        // Reset the state for a specific audio src
        resetStateForSrc(src: string) {
            if (this.audioState[src]) {
                this.audioState[src] = { lastPausedTime: 0, wasPlaying: false }
            }
        },
    },
    persist: typeof window !== 'undefined' && {
        enabled: true,
        strategies: [
            {
                key: 'audioStore',
                storage: localStorage,
            },
        ],
    },
})
