﻿<template>
  <component v-cloak :is="pageComponent" />
</template>

<script setup>
import { getComponentTypeForContent } from '@shared/api/component-selector'
import TitwAccountManagementPage from '@TodayInTheWord/components/pages/account-management/account-management.vue'
import TitwArticleDetailPage from '@TodayInTheWord/components/pages/article-index/article-detail-page.vue'
import TitwArticleIndexPage from '@TodayInTheWord/components/pages/article-index/article-index-page.vue'
import TitwAuthorDetailPage from '@TodayInTheWord/components/pages/author-detail/author-detail.vue'
import TitwBibleReadingExperiencePage from '@TodayInTheWord/components/pages/bible-reading-experience/bible-reading-experience.vue'
import TitwBibleVerseFeaturedCategoryPage from '@TodayInTheWord/components/pages/bible-verse-index/bible-verse-index-category-page.vue'
import TitwBibleVerseSearchPage from '@TodayInTheWord/components/pages/bible-verse-index/bible-verse-index-search-page.vue'
import TitwBibleVerseIndexPage from '@TodayInTheWord/components/pages/bible-verse-index/bible-verse-index.vue'
import TitwBibleVerseDetailTemplatePage from '@TodayInTheWord/components/pages/bible-verse/bible-verse-detail.vue'
import TitwDevotionalDetailPage from '@TodayInTheWord/components/pages/devotional-index/components/devotional-detail/devotional-detail.vue'
import TitwDevotionalCollectionIndexPage from '@TodayInTheWord/components/pages/devotional-index/devotional-index.vue'
import TitwDevotionalMonthlyStudyPage from '@TodayInTheWord/components/pages/devotional-monthly-study/devotional-monthly-study.vue'
import DiscussionStarterIndexPage from '@TodayInTheWord/components/pages/discussion-starter-index/discussion-starter-index.vue'
import DiscussionStarterDetailPage from '@TodayInTheWord/components/pages/discussion-starter/discussion-starter-detail.vue'
import ErrorPage from '@TodayInTheWord/components/pages/error-page.vue'
import TitwGeneralDetailPage from '@TodayInTheWord/components/pages/general-detail/general-detail.vue'
import TitwHomePage from '@TodayInTheWord/components/pages/home/home-page.vue'
import TitwLandingPage from '@TodayInTheWord/components/pages/landing/landing-page.vue'
import TitwQADetailPage from '@TodayInTheWord/components/pages/QA-detail/QA-detail-page.vue'
import TitwQAIndexPage from '@TodayInTheWord/components/pages/qa-index/qa-index-page.vue'
import TitwSearchBibleSegmentPage from '@TodayInTheWord/components/pages/search/search-bible-segment-page.vue'
import TitwSearchPage from '@TodayInTheWord/components/pages/search/search-page.vue'
import TitwSearchSegmentPage from '@TodayInTheWord/components/pages/search/search-segment-page.vue'
import TitwSearchSegmentPageBase from '@TodayInTheWord/components/pages/search/search-segment-page.vue'
import TitwVideoDetailPage from '@TodayInTheWord/components/pages/video-detail/video-detail.vue'
import TitwVideoIndexPage from '@TodayInTheWord/components/pages/video-index/video-index.vue'
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context'
import { useHead } from '@vueuse/head'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useAudioStore } from '@TodayInTheWord/stores/audio-store'

const components = {
  TitwAuthorDetailPage,
  TitwHomePage,
  TitwLandingPage,
  TitwBibleReadingExperiencePage,
  ErrorPage,
  TitwGeneralDetailPage,
  TitwVideoDetailPage,
  TitwVideoIndexPage,
  TitwAccountManagementPage,
  TitwArticleDetailPage,
  TitwBibleVerseDetailTemplatePage,
  TitwQADetailPage,
  TitwDevotionalDetailPage,
  TitwSearchPage,
  TitwSearchSegmentPageBase,
  TitwQAIndexPage,
  TitwSearchSegmentPage,
  TitwSearchBibleSegmentPage,
  TitwBibleVerseFeaturedCategoryPage,
  TitwBibleVerseIndexPage,
  TitwBibleVerseSearchPage,
  TitwDevotionalMonthlyStudyPage,
  DiscussionStarterDetailPage,
  TitwDevotionalCollectionIndexPage,
  DiscussionStarterIndexPage,
  TitwArticleIndexPage,
}

const epiStore = useEpiPageContextStore()

const pageComponent = computed(() => {
    if (epiStore.model.redirectUrl) {
        const router = useRouter()
        router?.push(epiStore.model.redirectUrl)
        return
    }
    const model = epiStore.model ?? {}
    if (!model) {
        return ErrorPage
    }

    return getComponentTypeForContent(model, components) ?? ErrorPage
})

const setupHeadElement = () => {
  const model = epiStore.model
  const metaModel = epiStore.metadata
  const metaInfo = {
    title: metaModel.seoTitle,
    meta: [],
    script: [],
    link: [],
    style: [],
  }

  if (metaModel) {
    for (const og in metaModel.openGraphTags) {
      metaInfo.meta.push({ name: og, content: metaModel.openGraphTags[og] })
    }
  }

  if (model) {
    // setup meta robots
    const robotsContent = []
    model.noIndex && robotsContent.push('noindex')
    model.noFollow && robotsContent.push('nofollow')
    if (robotsContent.length > 0) {
      metaInfo.meta.push({
        name: 'robots',
        content: robotsContent.join(', '),
      })
    }

    if (model.customizations) {
      for (const css in model.customizations.cssLinks) {
        metaInfo.link.push({
          rel: 'stylesheet',
          type: 'text/css',
          href: model.customizations.cssLinks[css],
        })
      }

      for (const js in model.customizations.javascriptLinks) {
        metaInfo.script.push({
          type: 'text/javascript',
          src: model.customizations.javascriptLinks[js],
        })
      }

      if (model.customizations.customJS !== null) {
        metaInfo.script.push({ children: model.customizations.customJS })
      }

      if (typeof document !== 'undefined') {
        applyCustomCss(document, model.customizations.customCss)

        const externalJavascript = document.getElementById('ExternalScriptLinks')
        if (externalJavascript) {
          externalJavascript.innerHTML = ''
          applyFullExternalScriptReferences(
            model.customizations?.externalJS,
            externalJavascript,
            document,
          )
        }
      }
    }
  }

  useHead(metaInfo)
}

const applyCustomCss = (doc, customCss) => {
  const customPageStyles = doc.getElementById('CustomPageStyles')
  if (customPageStyles) {
    if (customCSS !== null) {
      customPageStyles.innerHTML = customCSS
    } else {
      customPageStyles.innerHTML = ''
    }
  }
}

const applyFullExternalScriptReferences = (externalJS, externalJavascriptElement, doc) => {
  if (!externalJS || !externalJavascriptElement) {
    return
  }

  for (const js in externalJS) {
    const script = doc.createElement('script')
    script.src = externalJS[js].src
    script.type = externalJS[js].type
    script.integrity = externalJS[js].integrity
    script.async = true
    externalJavascriptElement.appendChild(script)
  }
}

setupHeadElement()
</script>
