import PageComponentSelector from '@TodayInTheWord/epi/page-component-selector.vue';
import { useEpiPageContextStore } from '@TodayInTheWord/stores/epi-page-context';
import { createMemoryHistory, createRouter, createWebHistory, START_LOCATION } from 'vue-router';
import { useAudioStore } from '@TodayInTheWord/stores/audio-store';

interface PathPosition {
    url: any
    scrollPosition: number
}

const router = createRouter({
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes: [
        {
            name: 'page-component-selector',
            path: '/:pathMatch(.*)*',
            component: PageComponentSelector,
            props: (route) => ({ key: route.fullPath }),
        },
    ],
    scrollBehavior: (to, from, savedPosition) => {},
})

if (typeof window !== 'undefined') {
    const scrollPositions = new Map<string, PathPosition>()
    const saveScrollPosition = (from: any) => {
        const scrollPosition = window.scrollY
        const pathPosition: PathPosition = {
            url: from,
            scrollPosition: scrollPosition,
        };
        scrollPositions.set(from.path, pathPosition)
    }

    const restoreScrollPosition = (to: any) => {
        let savedPosition = scrollPositions.get(to.path)
        if (savedPosition !== undefined) {
            if (to.path !== savedPosition?.url?.path) {
                savedPosition = undefined
            }
            if (to.query.p !== savedPosition?.url?.query?.p) {
                savedPosition = undefined
            }
        }
        if (savedPosition !== undefined) {
            window.scrollTo({ top: savedPosition.scrollPosition, behavior: 'instant' });
        } else if (to && to.hash && to.hash.length > 1) {
            const targetElement = document.querySelector(to.hash)

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'instant' })
            } else {
                window.scrollTo({ top: 0, behavior: 'instant' })
            }
        } else {
            window.scrollTo({ top: 0, behavior: 'instant' })
        }
    }

    // Update the beforeEach hook to save audio player state
    router.beforeEach(async (to, from) => {
        
        // Save the audio player state using Pinia store
        const audioStore = useAudioStore()
        audioStore.setPausedTime(audioStore.lastPausedTime || 0)
        audioStore.setWasPlaying(audioStore.wasPlaying)

        if (from === START_LOCATION || to.fullPath === from.fullPath) {
            return;
        }

        // Save scroll position
        saveScrollPosition(from)

        const epiPageContextStore = useEpiPageContextStore()
        await epiPageContextStore.fetchPageContext(to.fullPath)

        // Restore scroll position
        restoreScrollPosition(to)
    })

    // Update router.afterEach to restore audio playback state
    router.afterEach((to: any, from: any) => {

        // Restore audio player state after navigation
        const audioStore = useAudioStore()
        if (audioStore && typeof audioStore.resumePlayback === 'function') {
            audioStore.resumePlayback() // Use the newly defined resumePlayback method
        }

        const appInsights: any = window?.appInsights
        if (!appInsights) {
            return
        }

        const epiPageContext = useEpiPageContextStore()
        const pageViewTelemetry = {
            refUri: from?.fullPath,
            uri: to?.fullPath,
            pageType: epiPageContext.pageType,
            name: epiPageContext.metadata?.seoTitle,
        }

        if (epiPageContext.duration) {
            pageViewTelemetry.properties = {
                duration: epiPageContext.duration,
            }
        }

        appInsights.trackPageView(pageViewTelemetry)

    })
}

export default router;
