<template>
    <div>
        <div v-if="!props.isInList" class="audio-player bg-grey-50 border-grey-100 flex w-full flex-col space-y-3 rounded-xl border p-3 lg:p-4">
            <div class="flex justify-between">
                <div v-if="props.title" class="title text-light-blue-900 text-base font-medium leading-6">
                    {{ props.title }}
                </div>
                <div class="timer text-grey-700 text-base font-normal leading-6">
                    {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
                </div>
            </div>
            <div class="flex flex-row flex-wrap items-center justify-between lg:flex-nowrap lg:justify-normal">
                <div class="order-2 lg:order-none">
                    <button @click="togglePlayback" class="bg-light-blue-900 hover:bg-light-blue-700 mr-3 rounded-xl p-2 transition lg:p-3">
                        <span v-if="!isPlaying" class="flex items-center justify-center">
                            <PlayIcon class="h-6 w-6 text-white" />
                        </span>
                        <span v-if="isPlaying" class="flex items-center justify-center">
                            <PauseIcon class="h-6 w-6 text-white" />
                        </span>
                    </button>
                </div>

                <div class="order-1 mb-3 flex w-full lg:order-none lg:mb-0">
                    <input ref="audioSlider" type="range" class="audio-slider order-1" :value="currentTime" @input="seek" :min="0" :max="durationMax" :step="0.1" />
                </div>
                <div class="order-3 ml-3 flex space-x-2 lg:order-none">
                    <button @click="toggleRewind" class="bg-light-blue-600 hover:bg-light-blue-900 rounded-xl p-2 transition">
                        <span class="flex items-center justify-center">
                            <RewindSvg class="h-6 w-6 text-white" />
                        </span>
                    </button>
                    <button @click="toggleForward" class="bg-light-blue-600 hover:bg-light-blue-900 rounded-xl p-2 transition">
                        <span class="flex items-center justify-center">
                            <ForwardSvg class="h-6 w-6 text-white" />
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div v-else class="audio-player bg-grey-50 flex w-full flex-col space-y-3 py-4">
            <div class="flex flex-row items-center justify-between lg:flex-nowrap lg:justify-normal">
                <div class="lg:order-none">
                    <button @click="updateSelected" class="bg-light-blue-900 hover:bg-light-blue-700 mr-3 rounded-xl p-2 transition lg:p-3">
                        <span class="flex items-center justify-center">
                            <PlayIcon class="h-6 w-6 text-white" />
                        </span>
                    </button>
                </div>

                <div class="flex w-full flex-col lg:order-none lg:mb-0">
                    <div class="flex justify-between">
                        <div v-if="props.title" class="title text-light-blue-900 text-base font-medium leading-6">
                            {{ props.title }}
                        </div>
                        <div class="timer text-grey-700 text-base font-normal leading-6">
                            {{ formatTime(duration) }}
                        </div>
                    </div>
                    <div v-if="props.description" class="text-light-blue-900 text-xs font-normal leading-5">
                        {{ props.description }}
                    </div>
                    <div v-if="props.date" class="text-grey-600 text-xs font-normal leading-5">
                        {{ props.date }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { PauseIcon, PlayIcon } from '@heroicons/vue/24/solid'
    import ForwardSvg from '@TodayInTheWord/components/icons/forward-new-svg.vue'
    import RewindSvg from '@TodayInTheWord/components/icons/rewind-new-svg.vue'
    import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
    import { useAudioStore } from '@TodayInTheWord/stores/audio-store'

    interface AudioPlayerProps {
        title?: string
        src: string
        date?: string
        description?: string
        isInList?: boolean
    }

    const state = reactive({
        audio: null as HTMLAudioElement | null,
    })

    const props = defineProps<AudioPlayerProps>()
    const emit = defineEmits(['updateSelected'])
    const audioStore = useAudioStore()

    const isPlaying = ref<boolean>(false)
    const currentTime = ref<number>(0)
    const duration = ref<number>(0)
    const audioSlider = ref(null)

    const durationMax = computed(() => Math.floor(duration.value))

    // Function to format time in MM:SS format
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    }

    // Toggle playback (play or pause)
    const togglePlayback = () => {
        if (isPlaying.value) {
            state.audio?.pause()
        } else {
            state.audio!.currentTime = audioStore.getPausedTime(props.src) // Restore playback position from store based on src
            state.audio?.play()
        }
        isPlaying.value = !isPlaying.value

        // Persist playback state to the store based on src
        audioStore.setPausedTime(props.src, state.audio?.currentTime || 0)
        audioStore.setWasPlaying(props.src, isPlaying.value)
    }

    // Move audio forward by 15 seconds
    const toggleForward = () => {
        const newTime = Math.min(state.audio!.currentTime + 15, duration.value)
        state.audio!.currentTime = newTime
        currentTime.value = newTime

        // Persist new playback position to the store
        audioStore.setPausedTime(props.src, newTime)
        updateSliderStyle()
    }

    // Move audio backward by 15 seconds
    const toggleRewind = () => {
        const newTime = Math.max(state.audio!.currentTime - 15, 0)
        state.audio!.currentTime = newTime
        currentTime.value = newTime

        // Persist new playback position to the store
        audioStore.setPausedTime(props.src, newTime)
        updateSliderStyle()
    }

    // Function to update the current time and slider position
    const updateTimer = () => {
        currentTime.value = state.audio?.currentTime || 0
        updateSliderStyle()

        // Persist the current playback position based on src
        audioStore.setPausedTime(props.src, currentTime.value)
    }

    // Function to update the slider style
    const updateSliderStyle = () => {
        if (audioSlider.value) {
            const progress = duration.value !== 0 ? (currentTime.value / duration.value) * 100 : 0
            audioSlider.value.style.background = `linear-gradient(to right, #0369a1 ${progress}%, #bae6fd ${progress}%)`
        }
    }

    // Seek function to update the audio current time based on slider input
    const seek = (event: Event) => {
        const seekTime = (event.target as HTMLInputElement).valueAsNumber
        state.audio!.currentTime = seekTime
        currentTime.value = seekTime
        updateSliderStyle()
        audioStore.setPausedTime(props.src, seekTime) // Update store with seek time based on src
    }

    onMounted(() => {
        // Initialize the audio element and set the source
        state.audio = new Audio(props.src)

        // Set up event listeners for updating time and metadata
        state.audio.addEventListener('timeupdate', updateTimer)
        state.audio.addEventListener('loadedmetadata', () => {
            duration.value = state.audio!.duration
        })

        // Restore playback state from Pinia store based on src
        state.audio.currentTime = audioStore.getPausedTime(props.src)

        // Automatically resume playback if it was playing before navigation
        if (audioStore.getWasPlaying(props.src)) {
            // Wait for the metadata to load before playing
            state.audio.addEventListener('canplay', () => {
                state.audio!.play().catch((err) => {
                    console.error('Failed to auto-play audio:', err)
                })
                isPlaying.value = true
            })
        }
    })

    // onUnmounted lifecycle hook
    onUnmounted(() => {
        state.audio?.removeEventListener('timeupdate', updateTimer)
    })
</script>


<style lang="scss">
    .audio-player {
        .audio-slider {
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            cursor: pointer;
            outline: none;
            border-radius: 15px;
            height: 6px;
            background: #bae6fd;
        }

        .audio-slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 20px;
            width: 20px;
            background-color: #0369a1;
            border-radius: 50%;
            border: none;
        }

        .audio-slider::-moz-range-thumb {
            height: 20px;
            width: 20px;
            background-color: #0369a1;
            border-radius: 50%;
            border: none;
        }
    }
</style>
